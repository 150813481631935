'use strict';

import dispatcher from '../dispatcher';
import {EventEmitter} from 'UTILS';


let eventEmitter = new EventEmitter();
let initialized = false;
let items = {};

function _handleEvent(e) {
  switch (e.type){
    case ('form-add') :
      items[e.id] = {
        id: e.id,
        status: 'waiting',
        data: false
      };
      break;
    case ('form-remove') :
      if (!items.hasOwnProperty(e.id)) return;
      delete items[e.id];
      break;
    case ('form-send') :
      if (!items.hasOwnProperty(e.id)) return;
      if (items[e.id].status === 'sending') return;

      items[e.id].status = 'sending';
      items[e.id].data = e.data;

      eventEmitter.dispatch(_getData('change'));
      break;
    case ('form-submit') :
      if (!items.hasOwnProperty(e.id)) return;
      if (items[e.id].status === 'submitted') return;

      items[e.id].status = 'submitted';
      items[e.id].data = e.data;

      eventEmitter.dispatch(_getData('change'));
      break;
    case ('form-reset') :
      if (!items.hasOwnProperty(e.id)) return;
      if (items[e.id].status === 'waiting') return;

      items[e.id].status = 'waiting';
      items[e.id].data = e.data;

      eventEmitter.dispatch(_getData('change'));
      break;
  }
}




function _getData(type) {
  return {
    type: type,
    _module: 'form.store',
    items: items
  }
}

function _init() {
  dispatcher.subscribe(_handleEvent);
  initialized = true;
}

if (!initialized) _init();

export default {
  eventEmitter: eventEmitter,
  getData: _getData.bind(null, undefined)
}