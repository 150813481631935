import videoUrl from 'MEDIA/video/joom.mp4';



export class VideoButtonElement extends HTMLElement {
  private _video: HTMLVideoElement;

  constructor() {
    super();

    this.innerHTML = `
      <video src="${videoUrl}" loop muted autoplay playsinline></video>
    `;

    this._video = this.querySelector('video')!;

    let once = false;
    this._video.addEventListener('play', () => {
      if (once) return;
      this.classList.add('playing');
      once = true;
    });
  }
  connectedCallback() {
  }
  disconnectedCallback() {
  }
}


window.customElements.define('video-button', VideoButtonElement);