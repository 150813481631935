import {languages, LangKey, LangType} from './languages';
import {YTViewElement} from '../video/yotube-video.component';

type Item = {
  button: HTMLButtonElement;
  option: HTMLOptionElement;
  lang: LangType;
}
type Element = {
  el: HTMLElement,
  activate: (lang: LangType) => void
}

const DATA_KEY = 'joomIn-lang';



class LangElement extends HTMLElement {
  private readonly _select: HTMLSelectElement;
  private readonly _buttonsContainer: HTMLDivElement;
  private readonly _items: {
    [key in LangKey]?: Item;
  };
  private readonly _elements:{
    [key: string]: Element[];
  };
  private _lang: LangKey;
  private readonly _ytPlayers: YTViewElement[];


  constructor() {
    super();

    this._select = document.createElement('select');

    this._buttonsContainer = this.querySelector<HTMLDivElement>('.lang-buttons')!;
    this._items = {};
    this._elements = {};

    let lang: LangKey = 'en';
    languages.forEach(l => {
      const str = `^${l.key}\\b`;
      const reg = new RegExp(str).test(navigator.language);
      if (reg) {
        lang = l.key;
      }
    });

    this._lang = localStorage.getItem(DATA_KEY) as LangKey | null || lang;

    this._ytPlayers = Array.from(document.querySelectorAll<YTViewElement>('yt-video'));

    this._updateYT();
  }
  connectedCallback() {
    languages.forEach((lang) => {
      const button = document.createElement('button');
      button.classList.add('lang-button');
      button.innerHTML = lang.text;

      const option = document.createElement('option');
      option.value = lang.key;
      option.innerHTML = lang.text.toUpperCase();
      this._select.appendChild(option);

      button.addEventListener('click', () => this._setLang(lang.key));

      this._buttonsContainer.appendChild(button);

      this._items[lang.key] = {
        button,
        option,
        lang
      };
    });


    Array.from(document.querySelectorAll<HTMLElement | HTMLInputElement>('.langItem')).forEach((el) => {
      const id = el.dataset.id!;
      const obj: Element = {
        el,
        activate: (lang) => el.innerHTML = lang.translates[id]
      }

      if (id === 'svg') {
        const spans: {[key in LangKey]?: HTMLSpanElement} = {};
        let active: HTMLSpanElement;

        languages.forEach((lang) => {
          const span = document.createElement('span');
          span.classList.add('register-circle-icon-inner', lang.key);
          spans[lang.key] = span;
          el.appendChild(span);
        });

        obj.activate = (lang) => {
          if (active) active.classList.remove('active');
          spans[lang.key]!.classList.add('active');
          active = spans[lang.key]!
        }
      } else if (id === 'input') {
        const elem = el as HTMLInputElement
        obj.activate = (lang) => elem.value = lang.key;
      } else if (id === 'videoLink') {
        const elem = el as HTMLInputElement
        obj.activate = (lang) => el.innerHTML = "";
        obj.activate = (lang) => el.dataset.video = lang.translates[id];
        
      }

      if (!this._elements[id]) {
        this._elements[id] = [];
      }
      this._elements[id].push(obj);
    })


    this.appendChild(this._select);

    this._select.addEventListener('change', () => {
      const currentLang = this._select.value as LangKey;
      this._setLang(currentLang);
    });

    this._setLang(this._lang);
  }
  disconnectedCallback() {

  }


  _setLang(lang: LangKey) {
    const language = languages.find((l) => l.key === lang);
    const item = this._items[lang];
    if (!language || !item) return;

    const oldItem = this._items[this._lang];
    if (oldItem) oldItem.button.classList.remove('active');

    item.button.classList.add('active');

    for (let key in this._elements) {
      this._elements[key].forEach((obj: Element) => obj.activate(language));
    }

    this._select.value = lang;
    this._lang = lang;
    this._updateYT();
    document.documentElement.lang = lang;
    localStorage.setItem(DATA_KEY, lang);
  }
  _updateYT() {
    this._ytPlayers.forEach(player => {
      player.lang = this._lang;
    })
  }
}

window.customElements.define('lang-view', LangElement);