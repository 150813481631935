'use strict';

import dispatcher from '../dispatcher';
import store from './form.store';
import {LoaderElement} from '../loader/loader.component';


let idName = 'form-id-';
let idNum = 0;



function _getId() {
  idNum++;
  return idName + idNum;
}


function _check(field, once) {
  let successValidation = true;

  if (field.hasAttribute('is')) {
    successValidation = once ? !field.onceError : !field.error;
  } else {
    if (field.type === 'checkbox' && !field.checked) {
      field.parentNode.classList.add('error');
      successValidation = false;
    } else if (field.type === 'select-one' && field.selectedIndex === 0) {
      field.parentNode.classList.add('error');
      successValidation = false;
    } else if (!field.value || field.value === '') {
      field.parentNode.classList.add('error');
      successValidation = false;
    }

    if (!successValidation) {
      if (once) {
        setTimeout(() => {field.classList.remove('error')}, once);
      }

      field.addEventListener('input', () => field.classList.remove('error'), false);
      field.addEventListener('change', () => field.classList.remove('error'), false);
    }
  }

  return successValidation;
}


class Form extends HTMLFormElement {
  constructor() {
    super();

    this._status = false;

    this._loader = document.querySelector('loader-view');
    this._main = document.querySelector('main-view');
    this._button = this.querySelector('button');

    this.subscribe = e => this._subscribe(e);
    this.noAjaxSubmit = e => this._noAjaxSubmit(e);
    this.ajaxSubmit = e => this._ajaxSubmit(e);
  }

  connectedCallback() {
    this._id = this.dataset.id || this.id || _getId();
    this._action = this.action;
    this._once = +this.dataset.once;
    this._noAjax = this.dataset.ajax === 'false';
    this._requiredFields = Array.from(this.querySelectorAll('[data-required]'));
    this._serverValidationFileds = {};
    this._responseInner = this.querySelector('.form-response');

    Array.from(this.querySelectorAll('[data-validation]')).forEach(field => {
      const input = field.querySelector('input, textarea');

      this._serverValidationFileds[input.getAttribute('name')] = field;
    })

    if (this._noAjax) {
      this.addEventListener('submit', this.noAjaxSubmit);
    } else {
      this.addEventListener('submit', this.ajaxSubmit);
    }

    dispatcher.dispatch({
      type: 'form-add',
      _module: 'form.component',
      id: this._id
    });

    this._subscribe(store.getData());
    store.eventEmitter.subscribe(this.subscribe);
  }

  disconnectedCallback() {
    store.eventEmitter.unsubscribe(this.subscribe);

    dispatcher.dispatch({
      type: 'form-remove',
      _module: 'form.component',
      id: this._id
    });
  }

  _subscribe(e) {
    if (!e.items.hasOwnProperty(this._id) || e.items[this._id].status === this._status) return;

    this._status = e.items[this._id].status;
    this.classList.remove('waiting', 'sending', 'submitted');
    this.classList.add(this._status);
  }

  _validate() {
    let result = true;
    for (let i = 0; this._requiredFields.length > i; i++) {
      if (!_check(this._requiredFields[i], this._once)) result = false;
    }

    return result;
  }

  _noAjaxSubmit(e) {
    if (!this._validate() || this._status !== 'waiting') {
      e.preventDefault();
      return false
    }
  }
  // _onSuccess(e, data) {
  //   if (this._responseInner && e.message) {
  //     this._responseInner.innerHTML = e.message;
  //   }
  //
  //   dispatcher.dispatch({
  //     type: 'form-submit',
  //     _module: 'form.component',
  //     id: this._id,
  //     data: data
  //   });
  //
  //   setTimeout(() => {
  //     this.reset();
  //
  //     for (let key in this._serverValidationFileds) {
  //       if (this._serverValidationFileds.hasOwnProperty(key)) this._serverValidationFileds[key].reset();
  //     }
  //
  //     dispatcher.dispatch({
  //       type: 'form-reset',
  //       _module: 'form.component',
  //       id: this._id,
  //       data: false
  //     });
  //   }, 5000);
  // }

  _onSuccess(e, data) {
    dispatcher.dispatch({
      type: 'form-submit',
      _module: 'form.component',
      id: this._id,
      data: data
    });

    const callback = () => {
      this._button.classList.remove('collapsed');

      this._main.active = 'response';


      this.reset();

      for (let key in this._serverValidationFileds) {
        if (this._serverValidationFileds.hasOwnProperty(key)) this._serverValidationFileds[key].reset();
      }

      dispatcher.dispatch({
        type: 'form-reset',
        _module: 'form.component',
        id: this._id,
        data: false
      });

      setTimeout(() => {
        this._main.close();

        setTimeout(() => {
          this._loader.go(undefined, false, undefined, 'grey');
          setTimeout(() => this._main.active = 'index', 100)
        }, 400);
      }, 4000)
    }

    this._button.classList.add('collapsed');

    setTimeout(() => {
      this._isDisabled = false;
      this._loader.go(this._button, true, callback, 'grey');
    }, 200);
  }
  _onError(e) {
    this.classList.add('error');

    if (this._responseInner && e.message) {
      this._responseInner.innerHTML = e.message;
    }

    dispatcher.dispatch({
      type: 'form-reset',
      _module: 'form.component',
      id: this._id
    });
  }
  _onServerValidateError(e) {
    for (let key in e.errors) {
      if (e.errors.hasOwnProperty(key) && this._serverValidationFileds.hasOwnProperty(key)) {
        this._serverValidationFileds[key].error = e.errors[key][0];
      }
    }

    this._onError(e);
  }
  async _ajaxSubmit(e) {
    e.preventDefault();
    this.classList.remove('error');

    if (this._responseInner) {
      this._responseInner.innerHTML = '';
    }

    if (!this._validate() || this._status !== 'waiting') return;

    const data = new FormData(this);

    dispatcher.dispatch({
      type: 'form-send',
      _module: 'form.component',
      id: this._id,
      data: data
    });

    const response = await fetch(this._action, {
      method: 'POST',
      headers: {
        'accept': 'application/json'
      },
      body: data,
      mode: 'cors',
      cache: 'no-store'
    });

    if (response.ok) {
      console.log(response);
      const json = await response.json();
      this._onSuccess(json, data);
    } else {
      let json;

      if (response.status === 422 || response.status === 500) {
        json = await response.json();
        this._onServerValidateError(json);
      } else {
        this._onError({message: response.statusText});
      }
    }
  }
}

window.customElements.define('view-form', Form, {extends: 'form'});