import resizeStore from './resize.store';
const HTML = document.documentElement;



function _handleResize() {
  HTML.style.setProperty('--windowWidth', window.innerWidth + 'px');
  HTML.style.setProperty('--windowHeight', window.innerHeight + 'px');
}


const init = () =>  {
  _handleResize();
  resizeStore.eventEmitter.subscribe(_handleResize);
};

export default {
  init: init
}