export type EventEmitterHandler<T> = (data: T) => void;

export class EventEmitter<T> {
  private handlers: EventEmitterHandler<T>[];
  constructor() {
    this.handlers = [];
  }

  public dispatch(...args: T[]) {
    for (const argument of args) {
      this.handlers.forEach(handler => handler(argument));
    }
  }
  public subscribe(handler: EventEmitterHandler<T>) {
    if (!this.handlers.includes(handler)) this.handlers.push(handler);

    return this;
  }
  public unsubscribe(handler: EventEmitterHandler<T>) {
    const index = this.handlers.indexOf(handler);

    if (index >= 0) this.handlers.splice(index, 1);

    return this;
  }
}