import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../scss/app.scss';

// libraries



// utilities
import './dispatcher';
import './utils/utilities/outliner';


// import GlobalStore from './store/store';
// console.log(GlobalStore)

// components
import './video/yotube-video.component';
import './video/video-button.component';
import './main/main-view.component';
import './lang/lang-view.component';
import './form/field-focus.component';
import './form/checkbox.component';
import './form/form.component';
import './loader/loader.component';
import './words-view/words-view.component';


// modules
import './cursor/cursor-doc';
import resizeView from './resize/resize.view';


const init = () => {
  resizeView.init();
}



if (document.readyState !== 'loading') {
  init();
} else {
  document.addEventListener('DOMContentLoaded', init);
}
