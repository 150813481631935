const WORDS = ['Joom in', 'Zoom in', 'Look in', 'Loop in', 'Soon in', 'Joom in <i>&nbsp;2021</i>', '10.00 <i>&nbsp;CET</i>', 'Join in<i>!</i>'];
const WORDS_MOBILE = ['Joom in', 'Zoom in', 'Look in', 'Loop in', 'Soon in', 'Joom in', '2021', '10.00', 'CET', 'Join in!'];


export class WordsViewElement extends HTMLElement {
  private readonly _innerDesktop: HTMLDivElement;
  private readonly _innerMobile: HTMLDivElement;
  private readonly _wordsDesktop: HTMLDivElement[];
  private readonly _wordsMobile: HTMLDivElement[];

  constructor() {
    super();

    this._innerDesktop = this.querySelector<HTMLDivElement>('.wordsInnerDesktop')!;
    this._innerMobile = this.querySelector<HTMLDivElement>('.wordsInnerMobile')!;

    this._wordsDesktop = WORDS.map((el, i) => WordsViewElement._transform(el, i, this._innerDesktop));
    this._wordsMobile = WORDS_MOBILE.map((el, i) => WordsViewElement._transform(el, i, this._innerMobile));
  }
  connectedCallback() {
  }
  disconnectedCallback() {
  }

  private static _transform(word: string, index: number, container: HTMLDivElement) {
    const el = document.createElement('div');
    el.classList.add('words-word');
    el.innerHTML = word;

    if (index !== 0) el.style.opacity = '0';

    container.appendChild(el);

    return el;
  }

  public visible() {
    this.classList.add('visible');
  }
  public async play() {
    this.visible();

    const words = window.innerWidth < 860 ? this._wordsMobile : this._wordsDesktop;
    const duration = 330;

    for (let i = 0; words.length > i; i++) {
      const el = words[i];

      await new Promise<void>((resolve, reject) => {
        el.style.opacity = '1';
        setTimeout(() => {
          el.style.opacity = '0';
          resolve();
        }, duration);
      })
    }
  }
}


window.customElements.define('words-view', WordsViewElement);