import resizeStore, {ResizeData} from '../resize/resize.store';
import {isTouch} from "UTILS";

export type Handler = (e: {x: number, y: number}) => void;


class MoveClass {
  private readonly _onUpdateArray: Handler[];

  private _vw: number;
  private _vh: number;

  private _newX: number;
  private _newY: number;
  private _aniX: number;
  private _aniY: number;
  private _RESISTANCE: number;

  private readonly onMove: (e: MouseEvent) => void;
  private readonly onResize: (e: ResizeData) => void;
  private readonly tick: () => void;

  private _raf: number;


  constructor() {
    this._onUpdateArray = [];

    this._vw = 0;
    this._vh = 0;

    this._newX = 0;
    this._newY = 0;
    this._aniX = 0;
    this._aniY = 0;
    this._RESISTANCE = 0.05;

    this.onMove = (e) => this._onMove(e);
    this.onResize = (e) => this._onResize(e);
    this.tick = () => this._tick();

    this._raf = 0;

    if (isTouch()) return;
    this._onResize(resizeStore.getData());
    document.documentElement.addEventListener('mousemove', this.onMove);

    resizeStore.eventEmitter.subscribe(this.onResize);

    this._raf = requestAnimationFrame(this.tick);
  }

  private _onMove(e: MouseEvent) {
    this._newX = (e.clientX - this._vw / 2) / this._vw;
    this._newY = (e.clientY - this._vh / 2) / this._vh;
  }

  private _onResize(e: ResizeData) {
    this._vw = e.width;
    this._vh = e.height;
  }

  _tick() {
    const nx = (this._newX - this._aniX) * this._RESISTANCE;
    const ny = (this._newY - this._aniY) * this._RESISTANCE;

    this._aniX += nx;
    this._aniY += ny;

    this._onUpdateArray.forEach((func) => func({x: this._aniX, y: this._aniY}));

    this._raf = requestAnimationFrame(this.tick);
  }

  get x() {
    return this._aniX;
  }
  get y() {
    return this._aniY;
  }

  public addListener(func: Handler) {
    this._onUpdateArray.push(func);
  }
  public removeListener(func: Handler) {
    for (let i = 0; this._onUpdateArray.length > i; i++ ) {
      if (this._onUpdateArray[i] === func) {
        this._onUpdateArray.splice(i, 1);
        break;
      }
    }
  }
}

export const Move = new MoveClass();