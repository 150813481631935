import {gsap} from 'gsap';

interface MyWindow extends Window {
  onYouTubeIframeAPIReady: () => void
}

declare var window: MyWindow;

const idName = 'video-id-';
let idNum = 0;
const items: {[key: string]: YTViewElement} = {};

let last: YTViewElement | null = null;
let initialized = false;



const _getId = () => idName + idNum++;



const initPlayer = () => {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = '//www.youtube.com/player_api';

  document.head.appendChild(script);

  window.onYouTubeIframeAPIReady = () => {
    for (let key in items) {
      if (items.hasOwnProperty(key)) items[key]._build();
    }

    initialized = true;
  }
}




export class YTViewElement extends HTMLElement {
  private _isPlaying: boolean;
  private _isInitialized: boolean;

  private _video?: string;
  private readonly _id: string;
  private _lang?: string;

  private readonly _button: HTMLElement | null;
  private readonly _container: this;

  private readonly onClick: (e: MouseEvent) => void;
  private readonly onStateChange: (e: any) => void;
  private readonly onReady: () => void;
  private _player: any;

  constructor() {
    super();

    this._isPlaying = false;
    this._isInitialized = false;
    let videoString = this.dataset.video;

    if (!videoString) {
      console.warn('Missing video id');
      this._video = '';
    } else {
      this._video = videoString.substring(videoString.indexOf('=', 0) + 1, videoString.length);
    }

    this._id = this.dataset.id || this.id || _getId();
    this._button = this.querySelector<HTMLElement>('.videoButton');
    this._container = this;

    items[this._id] = this;

    if (!initialized) {
      initPlayer();
    } else {
      this._build();
    }

    this.onClick = (e) => this._click(e);
    this.onStateChange = (e) => this._onStateChange(e);
    this.onReady = () => this._onReady();
  }
  connectedCallback() {
    if (this._button) this._button.addEventListener('click', this.click);
  }
  disconnectedCallback() {
    if (this._player) this._player.destroy();
    this._isPlaying = false;

    delete items[this._id];
  }



  public _build() {
    let container = document.createElement('div');

    this._player = new YT.Player(container, {
      videoId: this._video,
      playerVars: {
        rel: 0,
        // showInfo: 0,
        modestbranding: 1,
        hl: this._lang,
        playsinline: 1
      },
      events: {
        onStateChange: (e) => this.onStateChange(e),
        onReady: (e) => this.onReady()
      }
    });
  }
  public play() {
    if (!this._player) return;

    this._play();
  }
  public stop() {
    if (!this._player) return;

    this._stop();
  }
  public pause() {
    if (!this._player) return;

    this._player.pauseVideo();

    this._isPlaying = false;
  }
  public get isPlaying() {
    return this._isPlaying;
  }
  public set lang(lang: string) {
    this._lang = lang;
    this._video = this.dataset.video;
    const playing = this._isPlaying;

    if (this._player) {
      this._player.destroy();
      this._isInitialized = false;
      last = null;
    }

    if (initialized) this._build();
    if (playing) this._play();
  }



  private _onStateChange(e: YT.OnStateChangeEvent) {
    if (e.data === 0) this._stop();
  }
  private _onReady() {
    this._player.loadVideoById(this._video);
    this._player.playVideo();
  }

  private _click(e: MouseEvent) {
    e.preventDefault();

    this._play();
  }

  private _play() {
    if (last && last._isPlaying) last._stop();

    if (!this._isInitialized) {
      this._container.appendChild(this._player.getIframe());
      // this._player.playVideo();
      this._isInitialized = true;
    } else {
      this._player.playVideo();
    }

    this.classList.add('active');

    last = this;

    this._isPlaying = true;

    // gsap.to(this._container, {
    //   opacity: 1,
    //   duration: 0.3
    // });
  }

  private _stop() {
    if (!this._player) return;
    this._player.pauseVideo();

    this.classList.remove('active');

    this._isPlaying = false;

    // gsap.to(this._container, {
    //   opacity: 0,
    //   duration: 0.3
    // });
  }
}

window.customElements.define('yt-video', YTViewElement);