export type PerformanceHandler = (shift: number) => void;

// for 60 fps

class PerformanceClass{
  private readonly _onUpdateArray: PerformanceHandler[];
  private _previousPerformanceTime: number;
  private _raf: number;
  private readonly onAnimate: () => void;

  constructor() {
    this._onUpdateArray = [];
    this._previousPerformanceTime = 0;
    this.onAnimate = () => this._onAnimate();
    this._raf = requestAnimationFrame(this.onAnimate);
  }

  public addListener(func: PerformanceHandler) {
    this._onUpdateArray.push(func);
  }
  public removeListener(func: PerformanceHandler) {
    for (let i = 0; this._onUpdateArray.length > i; i++ ) {
      if (this._onUpdateArray[i] === func) {
        this._onUpdateArray.splice(i, 1);
        break;
      }
    }
  }

  _onAnimate() {
    const now = performance.now();
    const performanceShift = (now - this._previousPerformanceTime) / 16;

    this._onUpdateArray.forEach((func) => func(performanceShift));

    this._previousPerformanceTime = now;
    this._raf = requestAnimationFrame(this.onAnimate);
  }
}

export const Performance = new PerformanceClass();