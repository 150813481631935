export type LangKey = 'en' | 'ru' | 'fr' | 'es' | 'de' | 'zh' | 'ja' | 'ko' | 'th' | 'tr';

export type LangType = {
  key: LangKey;
  text: string;
  translates: {
    [key: string]: string;
  }
}

export const languages: LangType[] = [
  {
    key: 'en',
    text: 'en',
    translates: {
      date: 'March 26th, 2021, 10:00 CET',
      button: 'Join In',
      inputName: 'Full Name',
      inputEmail: 'Email',
      inputCompany: 'Company',
      submit: 'Join In',
      checkbox: 'I have read and accept the <a target="_blank" href="https://www.joom.com/en/privacy">Privacy Policy</a>.',
      success: 'Thank you. Your registration is&nbsp;complete',
      buble_country: '100+<br>countries',
      buble_marketplace: 'Global<br>eCommerce<br>Marketplace',
      playButton: 'Play &nbsp;',
      videoLink: 'ENBSS5gLE7M',
    }
  },
  {
    key: 'ru',
    text: 'ru',
    translates: {
      date: '26 Марта 2021, 10:00 CET',
      button: 'Зарегистрироваться',
      inputName: 'ФИО',
      inputEmail: 'Email',
      inputCompany: 'Компания',
      submit: 'Зарегистрироваться',
      checkbox: 'Я подтверждаю, что ознакомился и&nbsp;согласен с&nbsp;настоящими <a target="_blank" href="https://www.joom.com/ru/privacy">Политикой конфиденциальности</a>.',
      success: 'Спасибо. Вы&nbsp;зарегистрированы! На&nbsp;указанный email вам было направлено письмо с&nbsp;подтверждением регистрации.',
      buble_country: '100+<br>стран',
      buble_marketplace: 'Глобальный<br>маркетплейс',
      playButton: 'Смотреть',
      videoLink: 'Tqj9uS5YnYY',
    }
  },
  {
    key: 'fr',
    text: 'fr',
    translates: {
      date: '26 Mars 2021, 10:00 CET',
      button: 'S\'inscrire',
      inputName: 'Nom et prénom',
      inputEmail: 'E-mail',
      inputCompany: 'Entreprise',
      submit: 'S\'inscrire',
      checkbox: 'En continuant, j\'accepte la <a target="_blank" href="https://www.joom.com/fr/privacy">Politique de Confidentialité</a>.',
      success: "Merci. Votre inscription est terminée. L\'e-mail de confirmation de votre inscription a été envoyé à votre adresse électronique.",
      buble_country: '100+<br>pays',
      buble_marketplace: 'Marketplace internationale',
      playButton: 'Voir &nbsp;&nbsp;',
      videoLink: '09LHQUbPKE4',
    }
  },
  {
    key: 'es',
    text: 'es',
    translates: {
      date: '26 Marzo 2021, 10:00 CET',
      button: 'Registrarse',
      inputName: 'Nombre y apellidos',
      inputEmail: 'Correo electrónico',
      inputCompany: 'Compañía',
      submit: 'Registrarse',
      checkbox: 'Al continuar acepto la <a target="_blank" href="https://www.joom.com/es/privacy">Política de Privacidad</a>.',
      success: 'Gracias. Ya&nbsp;está registrado. La&nbsp;carta confirmando su&nbsp;registro ha&nbsp;sido enviada a&nbsp;su&nbsp;correo electrónico.',
      buble_country: '100+<br>países',
      buble_marketplace: 'Marketplace ecommerce global',
      playButton: 'Ver &nbsp; &nbsp;',
      videoLink: 'Kpe71zavZGE',
    }
  },
  {
    key: 'de',
    text: 'de',
    translates: {
      date: '26. März 2021, 10:00 CET',
      button: 'Registrieren',
      inputName: 'Voller Name',
      inputEmail: 'E-Mail',
      inputCompany: 'Unternehmen',
      submit: 'Registrieren',
      checkbox: 'Weiterhin stimmen Sie <a target="_blank" href="https://www.joom.com/de/privacy">der Datenschutzrichtlinie</a> zu.',
      success: 'Dankeschön. Ihre Registrierung ist abgeschlossen. Der Brief zur Bestätigung Ihrer Registrierung wurde an Ihre E-Mail-Adresse gesendet.',
      buble_country: '100+<br>Länder',
      buble_marketplace: 'Die globale E-Commerce-Plattform',
      playButton: 'Ansehen',
      videoLink: 'w64NwToyaYU',
    }
  },
  {
    key: 'zh',
    text: 'zh',
    translates: {
      date: '2021年3月26日，世界標準時間10:00',
      button: '注冊',
      inputName: '姓名',
      inputEmail: '電子郵箱',
      inputCompany: '公司',
      submit: '注冊',
      checkbox: '在此，我接受<a target="_blank" href="https://www.joom.com/en/privacy">隱私政策。</a>',
      success: '謝謝你，您的註冊已完成，確認郵件已發送到您的郵箱',
      buble_country: '100多個國家',
      buble_marketplace: '全球電商平台',
      playButton: '播放視頻 &nbsp;',
      videoLink: 'Hcbq3dacQRg',
    }
  },
  {
    key: 'ja',
    text: 'jp',
    translates: {
      date: '2021年3月26日10:00CET',
      button: '登録',
      inputName: '氏名',
      inputEmail: '電子メイル',
      inputCompany: '会社',
      submit: '登録',
      checkbox: 'これにより、私は<a target="_blank" href="https://www.joom.com/en/privacy">プライバシーポリシーに同意します。</a>',
      success: 'ありがとうございました。登録が完了しました。登録を確認する手紙があなたの電子メールに送信されました。',
      buble_country: '100か国以上',
      buble_marketplace: '国境を越えるeコマースプラットフォーム',
      playButton: '見る &nbsp; &nbsp;',
      videoLink: 'VjXiz-qUzOI',
    }
  },
  {
    key: 'ko',
    text: 'kr',
    translates: {
      date: '2021 년 3 월 26 일 10:00 CET',
      button: '신청',
      inputName: '성명',
      inputEmail: '이메일',
      inputCompany: '회사 이름',
      submit: '신청',
      checkbox: '<a target="_blank" href="https://www.joom.com/kr/privacy">개인 정보 수집 및 이용</a> 정책을 읽고 동의합니다.',
      success: '감사합니다. 신청이 완료되었습니다. 신청을 인증 메일은 작성하신 이메일로 전송되었습니다.',
      buble_country: '100 개 이상의 국가',
      buble_marketplace: '글로벌 전자상거래 마켓플레이스',
      playButton: '재생 &nbsp;',
      videoLink: 'l4fwE6osJds',
    }
  },
  {
    key: 'th',
    text: 'th',
    translates: {
      date: '26 มีนาคม 2564, 10:00 CET',
      button: 'ลงทะเบียน',
      inputName: 'ชื่อเต็ม',
      inputEmail: 'อีเมล์',
      inputCompany: 'บริษัท',
      submit: 'ลงทะเบียน',
      checkbox: 'ด้วยการดำเนินใช้บริการ Joom ต่อ ถือว่ายินยอมที่จะปฏิบัติตามเงื่อนไขการให้บริการและน<a target="_blank" href="https://www.joom.com/th/privacy">โยบายความเป็นส่วนตัว</a>',
      success: 'ขอบคุณค่ะ การลงทะเบียนของคุณเสร็จสมบูรณ์ จดหมายยืนยันการลงทะเบียนถูกส่งไปยังอีเมลของคุณแล้ว',
      buble_country: '100+<br>ประเทศ',
      buble_marketplace: 'แพลตฟอร์มอีคอมเมิร์ซข้ามพรมแดน',
      playButton: 'ดู &nbsp; &nbsp;&nbsp;',
      videoLink: 'bYeSyfh6_JM',
    }
  },
  {
    key: 'tr',
    text: 'tr',
    translates: {
      date: '26 Mart 2021, 10:00 CET',
      button: 'Kayıt ol',
      inputName: 'Ad Soyad',
      inputEmail: 'E-posta',
      inputCompany: 'Şirket',
      submit: 'Kayıt ol',
      checkbox: 'Devam ederek <a target="_blank" href="https://www.joom.com/tr/privacy">Gizlilik Politikası</a>\'nı okuduğumu ve kabul ettiğimi onaylıyorum.',
      success: 'Teşekkür ederiz, kaydınız tamamlandı. Onay bilgisi e-posta adresinize gönderildi.',
      buble_country: '100&nbsp;\'den<br>fazla ülke',
      buble_marketplace: 'Küresel e-ticaret platformu',
      playButton: 'Oynat &nbsp;',
      videoLink: '_bhuUFJ3wHs',
    }
  }
]