import ResizeStore, {ResizeData} from '../resize/resize.store';
import {gsap} from 'gsap';

const RESOLUTION = window.devicePixelRatio || 1;

const RED = '#F64343';
const GREY = '#3D3F56';



export class LoaderElement extends HTMLElement {
  private _isLoaded: boolean;

  private _vw: number;
  private _vh: number;

  private _color: string;
  private _radius: number;
  private _radiusMax: number;
  private _x: number;
  private _y: number;

  private readonly _buttonOrient: HTMLDivElement;

  private readonly _canvas: HTMLCanvasElement;
  private readonly _context: CanvasRenderingContext2D;

  private readonly onResize: (e: ResizeData) => void;
  private readonly onUpdate: () => void;

  constructor() {
    super();

    this._isLoaded = false;

    this._vw = 1;
    this._vh = 1;

    this._color = RED;
    this._radius = 0;
    this._radiusMax = 0;
    this._x = 0;
    this._y = 0;

    this._buttonOrient = document.querySelector<HTMLDivElement>('.play-circle')!;

    this._canvas = document.createElement('canvas');
    this._context = this._canvas.getContext('2d')!;

    this.onResize = (e) => this._onResize(e);
    this.onUpdate = () => this._onUpdate();
  }
  connectedCallback() {
    this.appendChild(this._canvas);
    setTimeout(() => this._load(), 1000);
  }
  disconnectedCallback() {
    ResizeStore.eventEmitter.unsubscribe(this.onResize);
  }

  private _load() {
    this._onResize(ResizeStore.getData());

    this._radius = this._radiusMax;

    gsap.to(this, {
      _radius: 0,
      duration: 1,
      onUpdate: this.onUpdate,
      onStart: () => {
        this.style.background = 'none';
      },
      onComplete: () => {
        this._isLoaded = true;
        document.documentElement.classList.add('loaded');
        this.classList.add('waiting');

        setTimeout(() => document.documentElement.classList.add('langHidden'), 2000);
      }
    })

    this._onUpdate();

    ResizeStore.eventEmitter.subscribe(this.onResize);
  }

  public go(el?: HTMLElement, open = true, callback = () => {}, color: 'red' | 'grey' = 'red') {
    if (el) {
      const bounds = el.getBoundingClientRect();
      this._x = ((window.innerWidth >= 860 ? bounds.height : bounds.width) / 2 + bounds.left) * RESOLUTION;
      this._y = (bounds.height / 2 + bounds.top) * RESOLUTION;
    } else {
      this._x = this._vw / 2;
      this._y = this._vh / 2;
    }

    if (color === 'red') {
      this._color = RED;
    } else if (color === 'grey') {
      this._color = GREY;
    }

    let from: number;
    let to: number;

    if (open) {
      from = 0;
      to = this._radiusMax;
    } else {
      from = this._radiusMax;
      to = 0;
    }

    gsap.fromTo(this, {
      _radius: from,
    }, {
      _radius: to,
      duration: 1,
      onUpdate: this.onUpdate,
      ease: open ? 'expo.in' : 'expo.out',
      onStart: () => {
        this.classList.remove('waiting');
      },
      onComplete: () => {
        this.classList.add('waiting');

        callback();
      }
    })
  }

  private _onResize(e: ResizeData) {
    this._vw = this._canvas.width = e.width * RESOLUTION;
    this._vh = this._canvas.height = e.height * RESOLUTION;

    if (!this._isLoaded) {
      const bounds = this._buttonOrient.getBoundingClientRect();

      this._x = (bounds.left + bounds.width / 2) * RESOLUTION;
      this._y = (bounds.top + bounds.height / 2) * RESOLUTION;

      // if (e.width >= 860) {
      //   this._x = this._vw * 0.66;
      //   this._y = this._vh / 2;
      // } else {
      //   this._x = this._vw / 2;
      //   this._y = this._vh * 0.58;
      // }
    }

    this._radiusMax = Math.sqrt(Math.pow(this._vw, 2) + Math.pow(this._vh, 2));
  }

  private _onUpdate() {
    this._context.clearRect(0, 0, this._vw, this._vh);

    this._context.beginPath();
    this._context.fillStyle = this._color;
    this._context.arc(this._x, this._y, this._radius, 0, Math.PI * 2);
    this._context.fill();

    document.documentElement.style.setProperty('--circle', `${this._radius / RESOLUTION}px at ${this._x / RESOLUTION}px ${this._y / RESOLUTION}px`);
  }
}


window.customElements.define('loader-view', LoaderElement);