import {gsap} from 'gsap';
import {LoaderElement} from '../loader/loader.component';
import {WordsViewElement} from '../words-view/words-view.component';
import {YTViewElement} from '../video/yotube-video.component';

type Section = {
  el: HTMLElement;
  invert?: boolean;
}


class MainElement extends HTMLElement {
  private _isDisabled: boolean;
  private readonly _loader: LoaderElement;
  private readonly _words: WordsViewElement;
  private readonly _yt: YTViewElement;

  private readonly _sections: {
    [key: string]: Section;
  }
  private _active?: Section;

  constructor() {
    super();

    this._isDisabled = false;

    this._loader = document.querySelector<LoaderElement>('loader-view')!;
    this._words = document.querySelector<WordsViewElement>('words-view')!;
    this._yt = document.querySelector<YTViewElement>('yt-video')!;
    this._sections = {};

    Array.from(this.querySelectorAll('section')).forEach((el) => {
      const id = el.dataset.section!;

      this._sections[id] = {
        el,
        invert: el.dataset.invert === 'true'
      }

      if (el.classList.contains('active')) {
        this._active = this._sections[id];
      }
    });

    Array.from(document.querySelectorAll<HTMLElement>('.mainButton')).forEach((button) => {
      const id = button.dataset.section!;
      const loader = button.dataset.loader;

      if (loader === 'button') {
        const callBack = () => {
          button.classList.remove('collapsed');
          this._activate(id);
        }
        button.addEventListener('click', () => {
          if (this._isDisabled) return;
          this._isDisabled = true;

          button.classList.add('collapsed');
          setTimeout(() => {
            if (id === 'video') {
              // this._words.visible();
              // this._words.play().then(() => {
                this._isDisabled = false;
                this._sections[id].el.classList.add('activeAnimation');
                this._yt.play();
              // })
            } else {
              this._isDisabled = false;
            }

            this._loader.go(button, true, callBack);
          }, 200);
        });
      } else if (loader === 'back') {
        button.addEventListener('click', () => {
          if (this._isDisabled) return;
          this._isDisabled = true;
          this.close();

          setTimeout(() => {
            this._isDisabled = false;
            this._loader.go(button, false);
            setTimeout(() => this._activate(id), 100)
          }, 400);
        });
      } else {
        button.addEventListener('click', () => {
          if (this._isDisabled) return;
          this._activate(id)
        });
      }
    })
  }
  connectedCallback() {
    const id = 'index';
    this._sections[id].el.classList.add('active');

    setTimeout(() => {
      this._activate('index');
    }, 2000)
  }
  disconnectedCallback() {

  }

  public set active(id: string) {
    this._activate(id);
  }

  public close() {
    if (this._active) this._active.el.classList.remove('activeAnimation');
  }

  private _activate(id: string) {
    const section = this._sections[id];
    if (!section) return;

    if (this._active) {
      this._active.el.classList.remove('active', 'activeAnimation');
      if (id === 'index') gsap.set(section.el, {'--scale': 0, overwrite: true});
    }

    if (section.invert) {
      document.documentElement.classList.add('invert');
    } else {
      document.documentElement.classList.remove('invert');
    }

    section.el.classList.add('active');

    if (id === 'video') {
    } else {
      if (this._yt.isPlaying) this._yt.stop();

      setTimeout(() => {
        section.el.classList.add('activeAnimation');
        if (id === 'index') {
          gsap.to(section.el, {
            '--scale': 1,
            duration: 1.4
          });
        }
      }, 10)
    }


    this._active = section;
  }
}

window.customElements.define('main-view', MainElement);