'use strict';

import dispatcher from '../dispatcher';

class CheckboxWrapper extends HTMLLabelElement{
  constructor() {
    super();

    this._checked = false;
    this._onUpdateArray = [];

    this.subscribe = e => this._subscribe(e);
    this.onChange = e => this._onChange(e);
  }
  connectedCallback() {
    this._input = this.querySelector('input');
    this._input.addEventListener('change', this.onChange, false);

    this._onChange();
    dispatcher.subscribe(this.subscribe);
  }
  disconnectedCallback() {
    dispatcher.unsubscribe(this.subscribe);
  }

  _subscribe(e) {
    switch (e.type) {
      case 'form-reset' :
        this._onChange();
        break;
    }
  }
  _onChange() {
    this._checked = this._input.checked;

    this._onUpdateArray.forEach(func => func(this._checked, !this._checked));
  }
  addListener(func /*function*/) {
    if (typeof func !== 'function') return;

    this._onUpdateArray.push(func);
  }
  removeListener(func /*function*/) {
    if (typeof func !== 'function') return;

    for (let i = 0; this._onUpdateArray.length > i; i++ ) {
      if (this._onUpdateArray[i] === func) {
        this._onUpdateArray.splice(i, 1);
        break;
      }
    }
  }
  get error() {
    if (!this._checked) {
      this.classList.add('error');
    }

    return !this._checked;
  }
  get onceError() {
    if (!this._checked) {
      this.classList.remove('error');

      setTimeout(() => this.classList.add('error'), 0);

      clearTimeout(this._timer);

      this._timer = setTimeout(() => this.classList.remove('error'), 500);
    }

    return !this._checked;
  }
}

window.customElements.define('checkbox-wrapper', CheckboxWrapper, {extends: 'label'});