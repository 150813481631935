'use strict';

import dispatcher from '../dispatcher';
// import Inputmask from 'inputmask';

class InputWrapper extends HTMLLabelElement{
  constructor() {
    super();

    this._active = false;
    this._notEmpty = false;
    this._error = false;
    this._incorrect = false;
    this._timer = null;

    this._onUpdateArray = [];

    this.subscribe = e => this._subscribe(e);
    this.addFocus = e => this._addFocus(e);
    this.removeFocus = e => this._removeFocus(e);
    this.check = e => this._check(e);
  }
  connectedCallback() {
    this._field = this.querySelector('.field');
    this._form = this._field.form;
    this._notice = this.querySelector('.input-notice');

    // let type = this._field.getAttribute('type');

    // if (type === 'tel' || type === 'email') {
    // // if (type === 'tel') {
    //   this._masked = true;
    //   this._maskOptions = {
    //     // showMaskOnHover: false,
    //     clearMaskOnLostFocus: false,
    //     // showMaskOnFocus: false,
    //     // autoUnmask: true,
    //     placeholder: '_',
    //     onincomplete: () => this._onInComplete(),
    //     oncomplete: () => this._onComplete()
    //   };
    //
    //   if (type === 'tel') {
    //     this._maskOptions.mask = '+7 (999) 999-99-99';
    //   } else if (type === 'email') {
    //     this._field.setAttribute('type', 'text');
    //     this._maskOptions.alias = 'email'
    //   }
    //
    //   setTimeout(() => {
    //     Inputmask(this._maskOptions).mask(this._field);
    //
    //     this._mask = this._field.inputmask;
    //   }, 100)
    // }

    this._field.addEventListener('focus', this.addFocus, false);
    this._field.addEventListener('blur', this.removeFocus, false);

    this._field.addEventListener('input', this.check, false);
    this._field.addEventListener('change', this.check, false);

    this._check();

    dispatcher.subscribe(this.subscribe);
  }
  disconnectedCallback() {
    dispatcher.unsubscribe(this.subscribe);
  }

  _subscribe(e) {
    switch (e.type) {
      case 'drag-start':
      case 'navigation-emit':
        this._blur();
        break;
      case 'form-reset' :
        this._blur();
        this._check();
        break;
    }
  }
  _onInComplete() {
    this._error = true;
    this._incorrect = true;
    this.classList.remove('error', 'incorrect');
    this._notice.innerHTML = 'Поле не заполнено';
    setTimeout(() => this.classList.add('error', 'incorrect'), 10);
  }
  _onComplete() {
    this._error = false;
    this._incorrect = false;
    this.classList.remove('error', 'incorrect');
  }

  _check() {
    this._notEmpty = !!this._field.value;

    if (this._mask) {
      this._error = this._incorrect = !this._mask.isComplete();
    } else {
      this._error = this._incorrect = !this._notEmpty;
    }

    if (this._notEmpty) {
      this.classList.add('not-empty');
      if (this._error && !this._incorrect) {
        this.classList.remove('error');
        this._error = false;
      }
    } else {
      this.classList.remove('not-empty');
    }

    this._onUpdateArray.forEach(func => func(this._field.value, this._incorrect && this._error));
  }

  _addFocus() {
    this.classList.add('focus');
    this._form.classList.add('focus');
  }
  _removeFocus() {
    this.classList.remove('focus');
    this._form.classList.remove('focus');

    this._check();

    if (!this._masked) this._error = this._incorrect = !this._notEmpty;
  }
  _blur() {
    this.blur();

    this._removeFocus();
  }
  addListener(func /*function*/) {
    if (typeof func !== 'function') return;

    this._onUpdateArray.push(func);
  }
  removeListener(func /*function*/) {
    if (typeof func !== 'function') return;

    for (let i = 0; this._onUpdateArray.length > i; i++ ) {
      if (this._onUpdateArray[i] === func) {
        this._onUpdateArray.splice(i, 1);
        break;
      }
    }
  }
  get error() {
    if (this._incorrect && this._error) {
      this.classList.add('error');
      this._notice.innerHTML = 'Поле не заполнено';
    }

    return this._incorrect && this._error;
  }

  set error(message) {
    this._error = true;

    this._notice.innerHTML = message;

    this.classList.add('error');
  }
  get onceError() {
    if (this._incorrect && this._error) {
      this.classList.remove('error');
      this._notice.innerHTML = 'Поле не заполнено';

      setTimeout(() => this.classList.add('error'), 0);

      clearTimeout(this._timer);

      this._timer = setTimeout(() => this.classList.remove('error'), 500);
    }

    return this._incorrect && this._error;
  }
  reset() {
    this._error = false;
    this._field.value = '';

    this._notice.innerHTML = '';

    this.classList.remove('error', 'not-empty');
  }
}

window.customElements.define('input-wrapper', InputWrapper, {extends: 'label'});